import React from 'react'
import WorkDetails from '../components/WorkDetails'

function ProjectDetailsPage() {
  return (
    <div>
      <WorkDetails/>
    </div>
  )
}

export default ProjectDetailsPage
