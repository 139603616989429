import React from 'react'
import Projects from '../components/Projects'

function ProjectsPage() {
  return (
    <div>
      <Projects/>
    </div>
  )
}

export default ProjectsPage
